import React, { Component } from 'react';
import CTA from '../components/CTA';
import Calculator from '../components/Calculator';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  constructor() {
    super();

    this.state = {
      currency: '',
    };
  }

  componentDidMount() {
    Paddle.Product.Prices(599947, (prices) => {
      const isEUR = prices.price.gross.includes('€');
      this.setState({ currency: isEUR ? '€' : '$' });
    });
  }

  render() {
    const { currency } = this.state;
    return (
      <Layout>
        <SEO
          title="Your time is valuable"
          description="Find out how many minutes per month Polypane needs to save you to be worth it, and how many hours you can expect to save."
          keywords={['']}
          pathname={this.props.location.pathname}
          ogFileName="calculator"
          ogCustomPath="/og-custom/"
        />
        <PageHeader>
          <h1 style={{ zIndex: 1, position: 'relative' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-receipt-2"
              width="128"
              height="128"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#000000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
              <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5" />
            </svg>
            <br />
            Your time is valuable.
          </h1>
        </PageHeader>
        <Calculator currency={currency} />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
