import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import Duration from 'time-duration-stringify';

import Link from './link';

import ExpenseInfoBox from './pricetable/ExpenseInfoBox';

const styles = css`
  .calc {
    position: relative;
    max-width: 100rem;
    margin: 0 auto;
    text-align: left;
    position: relative;
    padding: 0 2rem;
    border-radius: 0.5rem;
    font-size: 14px;
    display: block;

    & h2 {
      font-size: clamp(18px, 16px + 4vw, 30px);
      line-height: 1.25;
      margin: auto;
      max-width: 60rem;
      color: #0a1530;
      font-weight: 700;
      margin-top: -2rem;
      margin-bottom: 1rem;

      @media (min-width: 30rem) {
        margin-top: -4rem;
        text-align: center;
      }

      & :is(b, strong) {
        font-weight: 900;
        background: #67b26f linear-gradient(135deg, #2c82ad, ease-in-out, #67b26f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }
    }

    & h3 {
      color: rgb(51, 51, 56);
      font-size: 22px;
      font-weight: 400;
      line-height: 1.1;
      max-width: 800px;
      margin: 0 auto 1rem;
      @media (min-width: 30rem) {
        text-align: center;
      }
    }

    & h3 + p {
      max-width: 800px;
      margin: 0 auto 4rem;
      font-size: 0.875rem;
      @media (min-width: 30rem) {
        text-align: center;
      }
    }

    & .multiplierexplained {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-size: 1.25rem;
      line-height: 1.2;
      margin: 4rem auto 0;
      max-width: 34rem;
      color: #0a1530;
      font-weight: 600;

      & svg,
      & [data-gatsby-image-wrapper] {
        width: 96px;
        height: 96px;
        flex: 0 0 96px;
        border-radius: 96px;
        border: 3px solid #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px;

        & img {
          border-radius: 100px;
        }
      }
    }

    & input[type='number'] {
      width: 50px;
      background: #fff;
      position: relative;
      display: inline-block;
      padding: 0.5rem;
      border-radius: 5px;
      border: 0;
      box-shadow:
        rgba(0, 0, 0, 0.025) 0 0 0 1px,
        rgba(0, 0, 0, 0.05) 0px 1px 0px,
        rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 30, 50, 0.1) 0px 2px 3px;
      font-feature-settings: 'tnum';
      font-size: inherit;
      text-align: center;
      margin-right: 0;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      @media (min-width: 840px) {
        width: 80px;
      }
    }

    & select {
      font-size: 1.125rem;
      max-width: 230px;
      background: #fff;
      position: relative;
      display: inline-block;
      padding: 0.5rem;
      border-radius: 5px;
      border: 0;
      box-shadow:
        rgba(0, 0, 0, 0.025) 0 0 0 1px,
        rgba(0, 0, 0, 0.05) 0px 1px 0px,
        rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 30, 50, 0.1) 0px 2px 3px;
      -webkit-appearance: none;
    }

    & option {
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .calcgrid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.2;
    flex-direction: column;
    max-width: 60rem;
    margin: 0 auto;

    @media (min-width: 840px) {
      flex-direction: row;
    }

    & label {
      display: flex;
      margin-bottom: 1rem;
      align-items: center;
      align-content: center;
      gap: 0.5rem;

      & svg {
        flex: 0 0 42px;
      }
    }

    & div > span {
      padding-left: calc(42px + 0.5rem);
    }

    & div {
      flex: 1 1 25%;
      position: relative;
      border-radius: 10px;
      border: 0px;
      outline: none;
      background: rgba(76, 162, 205, 0.075);
      box-shadow: rgba(0, 10, 20, 0.1) 0px 2px 1px inset;
      width: 100%;
      padding: 1rem 0.75rem;
      overflow: hidden;
    }
  }

  .savingGrid {
    padding-top: 4rem;
    text-align: center;
    font-size: clamp(18px, 16px + 4vw, 30px);
    letter-spacing: -0.015em;
    line-height: 1.25;
    margin: auto;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 80rem;
    color: #0a1530;
    font-weight: 700;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 3rem;
    flex-direction: column;

    @media (min-width: 840px) {
      flex-direction: row;
    }

    & span {
      flex: 1 1 33%;
    }

    & :is(b, strong) {
      font-weight: 900;
      background: linear-gradient(135deg, #2c82ad, ease-in-out, #67b26f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }

  .wrap {
    display: flex;
    justify-content: center;
    margin: 4rem 0 0;

    & h2 {
      text-align: left;
      margin: 0 0 1rem;
    }
  }
`;

const Calculator = ({ currency = 'EUR' }) => {
  const [rate, setRate] = useState(60);
  const [hours, setHours] = useState(4);
  const [multiplier, setMultiplier] = useState(2);

  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency === '€' ? 'EUR' : 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format;

  const setThisRate = (e) => {
    if (e.target.value !== '') {
      setRate(parseInt(e.target.value, 10) || 0);
    } else {
      setRate('');
    }
  };

  const setThisHours = (e) => {
    if (e.target.value !== '') {
      setHours(parseInt(e.target.value, 10) || 1);
    } else {
      setHours('');
    }
  };

  const setThisMultiplier = (e) => {
    if (e.target.value !== '') {
      setMultiplier(parseFloat(e.target.value) || 2);
    }
  };

  const rateperminute = (rate || 0) / 60;
  const timeFor1Month = parseInt(Math.round((11 / rateperminute) * 6) * 10000, 10) || 0;
  const month = Duration.toStringLong(timeFor1Month, true);
  const savedfor1month = parseInt((1 - 1 / parseFloat(multiplier)) * hours * 20, 10);

  return (
    <div className={[styles.calc].join(' ')}>
      <h2>
        Find out how many <b>minutes</b> it takes Polypane to be worth it.
        <br /> And how many <b>hours</b> you can expect to save.
      </h2>

      <h3>Polypane costs {currency}0.50 per workday*. Is your time worth it?</h3>
      <p>
        * {currency}0.45 for a yearly and {currency}0.55 for a monthly subscription, based on 20 workdays per month.
      </p>

      <div className={styles.calcs}>
        <div className={styles.freelancerCalc}>
          <div className={styles.calcgrid}>
            <div>
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-businessplan"
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <ellipse cx="16" cy="6" rx="5" ry="3" />
                  <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4" />
                  <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4" />
                  <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4" />
                  <path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                  <path d="M5 15v1m0 -8v1" />
                </svg>
                <span>How much your time costs (your employer):</span>
              </label>
              <span>
                <strong>{currency} </strong>
                <input type="number" min="0" step="5" value={rate} onInput={setThisRate} /> <strong> per hour</strong>
              </span>
            </div>
            <div>
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-devices-pc"
                  width="42"
                  height="42"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 5h6v14h-6z" />
                  <path d="M12 9h10v7h-10z" />
                  <path d="M14 19h6" />
                  <path d="M17 16v3" />
                  <path d="M6 13v.01" />
                  <path d="M6 16v.01" />
                </svg>
                <span>
                  How much web dev work you do <strong>in a day</strong>:
                </span>
              </label>
              <span>
                <input type="number" min="1" step="1" max="24" value={hours} onInput={setThisHours} />{' '}
                <strong>
                  hour
                  {hours === 1 ? '' : 's'}
                </strong>
              </span>
            </div>
            <div>
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-mood-smile"
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="9" y1="10" x2="9.01" y2="10" />
                  <line x1="15" y1="10" x2="15.01" y2="10" />
                  <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                </svg>
                <span>How you would describe yourself:</span>
              </label>
              <span>
                <select onChange={setThisMultiplier} value={multiplier}>
                  <option value={1.4}>Pessimist</option>
                  <option value={2}>Realist</option>
                  <option value={3}>Optimist</option>
                  <option value={5}>Extreme optimist</option>
                </select>
              </span>
            </div>
          </div>

          <div className={styles.multiplierexplained}>
            {multiplier === 5 ? (
              <>
                <StaticImage
                  src="../../assets/images/dale.jpg"
                  alt=""
                  objectFit="contain"
                  placeholder="none"
                  width={96}
                  imgStyle={{ transition: 'none' }}
                />
                <span>
                  <strong>Extreme optimist: </strong> Polypane will help you work five times as fast like{' '}
                  <Link to="/blog/how-code-increased-their-efficiency-by-500-maintaining-and-improving-websites-like-freshwater-slsc/">
                    the team at Code&
                  </Link>
                  .
                </span>
              </>
            ) : multiplier === 3 ? (
              <>
                <StaticImage
                  src="../../assets/images/vivian.jpg"
                  alt=""
                  objectFit="contain"
                  placeholder="none"
                  width={96}
                  imgStyle={{ transition: 'none' }}
                />
                <span>
                  <strong>Optimist:</strong> Polypane will help you work three times as fast like{' '}
                  <Link to="/blog/how-red-pixel-themes-uses-polypane/">Vivian at Red Pixel Themes</Link>.
                </span>
              </>
            ) : multiplier === 2 ? (
              <>
                <StaticImage
                  src="../../assets/images/testimonials/sean.jpg"
                  alt=""
                  objectFit="contain"
                  placeholder="none"
                  width={96}
                  imgStyle={{ transition: 'none' }}
                />
                <span>
                  <strong>Realist: </strong> Polypane will help you work twice as fast like{' '}
                  <Link to="/testimonials/">Sean, Jarod, Jai</Link> and many others.
                </span>
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-mood-empty"
                  width="96"
                  height="96"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="9" y1="10" x2="9.01" y2="10" />
                  <line x1="15" y1="10" x2="15.01" y2="10" />
                  <line x1="9" y1="15" x2="15" y2="15" />
                </svg>
                <span>
                  <strong>Pessimist:</strong> Polypane will help you work a little faster as you'll save time not having
                  to resize.
                </span>
              </>
            )}
          </div>
          <div className={styles.savingGrid}>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-clock"
                width="96"
                height="96"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="9" />
                <polyline points="12 7 12 12 15 15" />
              </svg>
              <br />
              You break even after <strong>{month}</strong> of your time saved.
            </span>
            <span>
              <svg
                className="w-64 h-64"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="96"
                height="96"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="6" y1="11" x2="10" y2="11" />
                <line x1="8" y1="9" x2="8" y2="13" />
                <line x1="15" y1="12" x2="15.01" y2="12" />
                <line x1="18" y1="10" x2="18.01" y2="10" />
                <path d="M17.32 5H6.68a4 4 0 00-3.978 3.59c-.006.052-.01.101-.017.152C2.604 9.416 2 14.456 2 16a3 3 0 003 3c1 0 1.5-.5 2-1l1.414-1.414A2 2 0 019.828 16h4.344a2 2 0 011.414.586L17 18c.5.5 1 1 2 1a3 3 0 003-3c0-1.545-.604-6.584-.685-7.258-.007-.05-.011-.1-.017-.151A4 4 0 0017.32 5z" />
              </svg>
              <br />
              You save <strong>{savedfor1month} hours</strong> each month for fun stuff!
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-calculator"
                width="96"
                height="96"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="4" y="3" width="16" height="18" rx="2" />
                <rect x="8" y="7" width="8" height="3" rx="1" />
                <line x1="8" y1="14" x2="8" y2="14.01" />
                <line x1="12" y1="14" x2="12" y2="14.01" />
                <line x1="16" y1="14" x2="16" y2="14.01" />
                <line x1="8" y1="17" x2="8" y2="17.01" />
                <line x1="12" y1="17" x2="12" y2="17.01" />
                <line x1="16" y1="17" x2="16" y2="17.01" />
              </svg>
              <br />
              That's worth <strong>{formatPrice(savedfor1month * rate)}</strong>, enough to pay for{' '}
              <strong>{Math.round((savedfor1month * rate) / 9)}</strong> months of Polypane.
            </span>
          </div>
        </div>
      </div>

      <div className={styles.wrap}>
        <ExpenseInfoBox />
      </div>
    </div>
  );
};

export default Calculator;
